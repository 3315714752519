import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import HomePage from './components/HomePage';
import EditPage from './components/EditPage';
import reducers from './redux/reducers';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import settings from './settings';
import AuthCallback from './AuthCallback';
import Auth0 from './Auth0';
import AppContainer from './AppContainer'

const loggerMiddleware = createLogger();

const middlewares = settings.env === "dev"
  ? applyMiddleware(thunkMiddleware, loggerMiddleware)
  : applyMiddleware(thunkMiddleware);

const store = createStore(
  reducers,
  middlewares
);

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true
    }
  }
  async componentDidMount() {
    if (window.location.pathname === '/callback') return;

    Auth0.silentAuth()
      .then(a => this.setState({ loading: false }))
      .catch(a => Auth0.signIn())

  }

  render() {
    return <Provider store={store}>
      <AppContainer>
        <Router>
          <Switch>
            <Route path="/callback"><AuthCallback /></Route>
            {!this.state.loading && <Route path="/edit"><EditPage /></Route>}
            {!this.state.loading && <Route path="/"><HomePage /></Route>}

          </Switch>
        </Router>
      </AppContainer>
    </Provider>

  }
}

export default App;
