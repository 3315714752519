import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import auth0Client from './Auth0';
import { Grid, Container } from '@material-ui/core/';

class Callback extends Component {
  componentDidMount() {
    auth0Client.handleAuthentication()
      .then((e) => {
        console.log("then", e);
        setTimeout(() => {
          window.location.href = '/';
        }, 200);
      })
      .catch(e => {
        console.log("catch", e);
        this.props.history.replace('/');
        window.location.reload();
      });
  }

  render() {
    return <div style={{ backgroundColor: "#FAFAFF" }}>
      <Container fixed maxWidth={false} style={{ padding: "10px 0 10px 0", height: "100px" }} className="topBar">
        <Grid container style={{ position: "relative", top: "5px" }} direction="row" alignItems="center" spacing={4} >
          <Grid item xs={3}>
            <img style={{ marginLeft: "30px" }} src="/oxance-logo-annuaire.svg" />
          </Grid>
        </Grid>
      </Container>
      <p style={{ textAlign: "center", marginTop: "50px", color: "#333" }}>Chargement...</p>
    </div>

  }
}

export default withRouter(Callback);