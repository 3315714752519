import settings from './settings';
import auth0 from './Auth0';

const fetchJson = (url, method = "get", body = undefined) => new Promise((resolve, reject) => {
    
    return fetch(url, Object.assign({
        method: method,
        headers: new Headers({
            'Authorization': 'Bearer ' + auth0.getIdToken(),
            'Content-Type': 'application/json'
        }),
    },
        !!body ? {body: JSON.stringify(body)} : {})
    ).then(response => {
        if(response.status > 204) {
            response.text().then(error => reject(error));
        } else {
            resolve(response.json());
        }
    }).catch(err => {
        reject(err);
    });
})

const HOST = settings.apiHost;

const Api = {
    test: {
        get: () => {
            return fetchJson(HOST + "/test")
        }
    },
    departements: {
        list: () => {
            return fetchJson(HOST + "/departements")
        }
    },
    categories: {
        list: () => {
            return fetchJson(HOST + "/categories")
        }
    },
    offices: {
        list: () => {
            return fetchJson(HOST + "/offices")
        },
        delete: (officeId) => {
            return fetchJson(HOST + "/offices/" + officeId, "DELETE")
        },
        get: (officeId) => {
            return fetchJson(HOST + "/offices/" + officeId)
        },
        update: (id, office) => {
            return fetchJson(HOST + "/offices/" + id, "POST", office)
        },
        create: (office) => {
            return fetchJson(HOST + "/offices", "POST", office)
        }
    }

};

export default Api;