const configs = {
    dev: {
        env: "dev",
        apiHost: "http://localhost:5000/api",
        auth: {
            clientId: "0Q7rIna4wXo6bNHhdv5LK7DwlO5LjJ7m",
            returnTo: "http://localhost:3000/backoffice",
            domain: "oxance.eu.auth0.com",
            audience: "https://oxance.eu.auth0.com/userinfo",
            redirectUri: "http://localhost:3000/backoffice/callback",
        }
    },
    prod: {
        env: "prod",
        apiHost: "https://api.appli-oxance.fr",
        auth: {
            clientId: "0Q7rIna4wXo6bNHhdv5LK7DwlO5LjJ7m",
            returnTo: "https://backoffice.appli-oxance.fr",
            domain: "oxance.eu.auth0.com",
            audience: "https://oxance.eu.auth0.com/userinfo",
            redirectUri: "https://backoffice.appli-oxance.fr/callback",
        }
    }
};


export default (document.location.href.indexOf("http://localhost") === -1) ? configs.prod : configs.dev;