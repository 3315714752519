import React, { Component } from 'react';
import {
    Grid,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    Checkbox,
    Container,
    MenuItem,
    IconButton,
    Button,
    InputLabel,
    Paper,
    Select,
    TextField,
    Link
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { dismissErrorMessage, doCreateOffice, addErrorMessage, doUpdateOffice, fetchCategories, fetchDepartements, doFetchOffice } from '../redux/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import TopBar from './TopBar';
import IconKeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import IconKeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import queryString from 'query-string';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Auth0 from '../Auth0';
import InputBase from '@material-ui/core/InputBase';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';


const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650
    },
};

const daysTranlations = {
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
    sunday: "Dimanche"
};

class HomePage extends Component {

    constructor(props) {
        super(props)
        const queryParam = queryString.parse(props.location.search);
        this.state = {
            isNewOffice: !queryParam.id,
            office: {
                specialities: [],
                departement: {},
                hours: {
                    monday: '',
                    tuesday: '',
                    wednesday: '',
                    thursday: '',
                    friday: '',
                    saturday: '',
                    sunday: ''
                }
            }
        };

        queryParam.id && this.fetchOffice(queryParam.id);
        props.fetchDepartements();
        props.fetchCategories();
        
    }

    fetchOffice = (id) => {
        this.props.fetchOffice(id).then(({ office, error }) => {
            if (!!error) {
                alert(error);
            } else {
                this.setState({ 
                    isNewOffice: false,
                    office 
                });
            }
        });
    }

    sortBy = (column) => {
        if (this.state.sortBy == column) {
            this.setState({
                order: -this.state.order,
            })
        } else {
            this.setState({
                order: 1,
                sortBy: column,
            })
        }

    }

    openPage = (page) => {
        this.setState({
            page
        })
    }


    handleOpenDeleteConfirmDialog = (officeId, officeName) => {
        this.setState({
            deleteConfirmDialog: {
                open: true,
                officeId,
                officeName,
            }
        });
    };

    handleDeleteConfirmDialogYesClick = () => {
        this.props.deleteOffice(this.state.deleteConfirmDialog.officeId)
            .then(() => {
                this.setState({
                    deleteConfirmDialog: {
                        open: false,
                        officeId: null,
                        officeName: null,
                    }
                });
            });
    };

    handleDeleteConfirmDialogNoClick = () => {
        this.setState({
            deleteConfirmDialog: {
                open: false,
                officeId: null,
                officeName: null,
            }
        });
    };


    upDown = (state, category) => {
        const sortingUp = state.sortBy === category && state.order < 0;
        const sortingDown = state.sortBy === category && state.order > 0;
        return <div style={{ width: "20px", position: "relative", top: "6px", display: "inline-flex" }} onClick={() => this.sortBy(category)}>
            <IconButton style={{ padding: 0, width: "30px", height: "30px" }} onClick={() => this.sortBy(category)}>
                <IconKeyboardArrowDown style={{ position: "relative", left: "12px", top: "6px", color: sortingUp ? "#04CDD9" : "#CCC" }} />
                <IconKeyboardArrowUp style={{ position: "relative", left: "-12px", top: "-5px", color: sortingDown ? "#04CDD9" : "#CCC" }} />
            </IconButton>
        </div>
    }

    handleMenuOpen = event => {
        this.setState({
            menuAnchor: event.currentTarget
        });
    };

    handleMenuClose = () => {
        this.setState({
            menuAnchor: null
        });
    };

    handleChange = (event) => {
        const field = event.target.name;
        const value = event.target.value;

        if (field.indexOf("hours.") === 0) {
            const day = field.substr(6);
            this.setState({
                office: {
                    ...this.state.office,
                    hours: {
                        ...this.state.office.hours,
                        [day]: value
                    }
                }
            });
        } else if (field.indexOf("speciality.") === 0) {

            const specialities = [...this.state.office.specialities];
            specialities[field.substr(11)] = value;
            this.setState({
                office: {
                    ...this.state.office,
                    specialities
                }
            });
        } else if (field === "departement.id") {
            this.setState({
                office: {
                    ...this.state.office,
                    departement: this.props.departements.find(d => d.id === value)
                }
            });
        } else {
            this.setState({
                office: {
                    ...this.state.office,
                    [event.target.name]: event.target.value
                }
            });
        }
    }

    handleChangeCategory = (category) => {
        this.setState({
            office: {
                ...this.state.office,
                category: this.props.categories.find(d => d.id === category).name
            }
        })
    }

    removeSpeciality = (index) => {
        const specialities = [...this.state.office.specialities];
        delete specialities[index];
        this.setState({
            office: {
                ...this.state.office,
                specialities
            }
        });
    }

    moveSpeciality = (index, movement) => {
        const specialities = [...this.state.office.specialities];
        const toBeMoved = specialities[index];
        specialities[index] = specialities[index + movement];
        specialities[index + movement] = toBeMoved;
        this.setState({
            office: {
                ...this.state.office,
                specialities
            }
        });
    }

    addSpeciality = () => {
        this.setState({
            office: {
                ...this.state.office,
                specialities: [
                    ...this.state.office.specialities,
                    ""
                ]
            }
        });
    }

    save = () => {
        if (!!this.state.office._id) {
            this.props.updateOffice(this.state.office._id, this.state.office)
                .then(_ => {
                    this.props.addErrorMessage({ id: "UPDATE_DONE", message: <p>L'établissement a été mis à jour. Cliquez <Link style={{ color: "#FF1C57", fontWeight: "bold", cursor: "pointer" }} onClick={() => this.props.dismissErrorMessage("UPDATE_DONE") && this.props.history.push('/')}>ici</Link> pour revenir à la liste</p>, type: "info" });
                })
                .catch(e => console.log(e));
        } else {
            this.props.createOffice(this.state.office).then(e => {
                this.props.history.push('/edit?id=' + e.office._id);
                this.fetchOffice(e.office._id);
                this.props.addErrorMessage({ id: "CREATION_DONE", message: <p>L'établissement a été créé. Cliquez <Link style={{ color: "#FF1C57", fontWeight: "bold", cursor: "pointer" }} onClick={() => this.props.dismissErrorMessage("CREATION_DONE") && this.props.history.push('/')}>ici</Link> pour revenir à la liste</p>, type: "info" });
            });
        }

    }

    render() {

        const office = this.state.office;

        return <div className="editPage" style={{ backgroundColor: "#FCFCFF" }}>
            <Container maxWidth={false} width={1} style={{ padding: "23px 0 10px 0", height: "100px" }} className="topBar">
                <Grid container style={{ position: "relative", top: "5px" }} direction="row" alignItems="center" spacing={4} >
                    <Grid item md={7} xs={6}>
                        <span style={{ color: "#FF1C57", fontSize: "25px", fontWeight: "bold", paddingLeft: "50px" }}>{office.name}</span>
                    </Grid>
                    <Grid item md={5} xs={6} style={{ textAlign: "right" }}>
                        <Button onClick={() => this.props.history.push('/')} className={"cancelButton"}>Annuler</Button>
                        <Button onClick={() => this.save()} className={"addButton"}>{ this.state.isNewOffice ? "Enregistrer" : "Mettre à jour" }</Button>
                    </Grid>
                </Grid>

            </Container>
            <Container maxWidth={false} width={1} style={{ padding: "30px" }}>
                <Grid container style={{ top: "5px", padding: "20px 30px" }} direction="row" alignItems="center" spacing={5} >
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="name-label">Établissement</InputLabel>
                        <TextField id="name" name="name" labelId="name-label" onChange={(event) => this.handleChange(event)} value={office.name}></TextField>
                    </Grid>
                </Grid>
                <Grid container style={{ top: "5px", backgroundColor: "rgba(235,237,248,0.50)", marginTop: "30px", padding: "30px" }} direction="row" alignItems="center" spacing={5} >
                    <Grid item md={12} xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <p style={{ fontSize: "20px", padding: 0, margin: 0 }}>Description</p>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                        <TextField id="description" name="description" rows={5} multiline={true} onChange={(event) => this.handleChange(event)} value={office.description}></TextField>
                    </Grid>
                </Grid>
                <Grid container style={{ top: "5px", backgroundColor: "rgba(235,237,248,0.50)", marginTop: "30px", padding: "30px" }} direction="row" alignItems="center" spacing={5} >
                    <Grid item md={12} xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <p style={{ fontSize: "20px", padding: 0, margin: 0 }}>Adresse</p>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="address1-label">Adresse</InputLabel>
                        <TextField id="address1" name="address1" labelId="address1-label" onChange={(event) => this.handleChange(event)} value={office.address1}></TextField>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}></Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="address2-label">Complément</InputLabel>
                        <TextField id="address2" name="address2" labelId="address2-label" onChange={(event) => this.handleChange(event)} value={office.address2}></TextField>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}></Grid>
                    <Grid item md={3} xs={12} md={1} style={{ paddingBottom: 0 }}>
                        <InputLabel id="postalcode-label">CP</InputLabel>
                        <TextField id="postalcode" name="postalcode" labelId="postalcode-label" onChange={(event) => this.handleChange(event)} value={office.postalcode}></TextField>
                    </Grid>
                    <Grid item md={3} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="city-label">Ville</InputLabel>
                        <TextField id="city" name="city" labelId="city-label" onChange={(event) => this.handleChange(event)} value={office.city}></TextField>
                    </Grid>
                    <Grid item md={2} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="departement-label">Département</InputLabel>
                        <Select name="departement.id" labelId="departement-label" id="demo-simple-select" value={office.departement && office.departement.id} onChange={(event) => this.handleChange(event)} >
                            {this.props.departements.map(departement => <MenuItem value={departement.id}>{departement.id} - {departement.name}</MenuItem>)}
                        </Select>
                    </Grid>
                </Grid>

                <Grid container style={{ top: "5px", backgroundColor: "rgba(235,237,248,0.50)", marginTop: "30px", padding: "30px" }} direction="row" alignItems="center" spacing={5} >
                    <Grid item md={12} xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <p style={{ fontSize: "20px", padding: 0, margin: 0 }}>Coordonnées</p>
                    </Grid>
                    <Grid item md={2} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="phone-label">Téléphone</InputLabel>
                        <TextField id="phone" name="phone" labelId="phone-label" onChange={(event) => this.handleChange(event)} value={office.phone}></TextField>
                    </Grid>
                    <Grid item md={4} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="email-label">Email</InputLabel>
                        <TextField id="email" name="email" labelId="email-label" onChange={(event) => this.handleChange(event)} value={office.email}></TextField>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}></Grid>

                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="rdv-label">URL rdv en ligne</InputLabel>
                        <TextField id="rdv" name="rdv" labelId="rdv-label" onChange={(event) => this.handleChange(event)} value={office.rdv}></TextField>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}></Grid>

                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}>
                        <InputLabel id="knowMore-label">URL En savoir plus</InputLabel>
                        <TextField id="knowMore" name="knowMore" labelId="knowMore-label" onChange={(event) => this.handleChange(event)} value={office.knowMore}></TextField>
                    </Grid>
                    <Grid item md={6} xs={12} style={{ paddingBottom: 0 }}></Grid>
                </Grid>

                <Grid container style={{ top: "5px", backgroundColor: "rgba(235,237,248,0.50)", marginTop: "30px", padding: "30px" }} direction="row" alignItems="center" spacing={5} >
                    <Grid item md={12} xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <p style={{ fontSize: "20px", padding: 0, margin: 0 }}>Catégories</p>
                    </Grid>
                    <Grid item md={12} xs={12} style={{ paddingBottom: 0 }}>
                        <FormControl component="fieldset" className={styles.formControl}>
                            {this.props.categories.map(category =>
                                <FormControlLabel
                                    control={<Checkbox checked={this.state.office.category === category.name} onChange={() => this.handleChangeCategory(category.id)} value={category.id} />}
                                    label={category.name}
                                />)}

                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container style={{ top: "5px", backgroundColor: "rgba(235,237,248,0.50)", marginTop: "30px", padding: "30px" }} direction="row" alignItems="center" spacing={5} >
                    <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                        <p style={{ fontSize: "20px", padding: 0, margin: 0 }}>Spécialités</p>
                    </Grid>
                    <Grid item xs={12} md={9} style={{ paddingBottom: 0 }}>
                        {this.state.office.specialities && this.state.office.specialities.map((speciality, index) =>
                            <Paper className="specialityPaper">
                                <Grid container>
                                    <Grid item md={9} xs={8}>
                                        <TextField id={"speciality-" + index} name={"speciality." + index} onChange={(event) => this.handleChange(event)} value={speciality}></TextField>
                                    </Grid>
                                    <Grid item md={3} xs={4} style={{ textAlign: "right" }}>
                                        <IconButton style={{ padding: "6px"}} disabled={index === this.state.office.specialities.length - 1} onClick={() => this.moveSpeciality(index, 1)}>
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                        <IconButton style={{ padding: "6px"}} disabled={index === 0} onClick={() => this.moveSpeciality(index, -1)}>
                                            <ArrowDropUpIcon />
                                        </IconButton>
                                        <IconButton style={{ padding: "6px"}} onClick={() => this.removeSpeciality(index)}>
                                            <DeleteIcon style={{ color: "#FF1C57" }} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )}
                        <Button onClick={() => this.addSpeciality()} style={{ color: "#FF1C57" }}>+ Ajouter une spécialité</Button>

                    </Grid>


                    <Grid container style={{ top: "5px", backgroundColor: "rgba(235,237,248,0.50)", marginTop: "30px", padding: "30px" }} direction="row" alignItems="center" spacing={5} >
                        <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                            <p style={{ fontSize: "20px", padding: 0, margin: 0 }}>Horaires</p>
                        </Grid>
                        {["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map(day =>
                            <Grid item xs={6} md={3} style={{ paddingBottom: 0 }}>
                                <InputLabel id={"hours." + day + "-label"}>{daysTranlations[day]}</InputLabel>
                                <TextField id={"hours." + day} name={"hours." + day} labelId={"hours." + day + "-label"} onChange={(event) => this.handleChange(event)} value={office.hours && office.hours[day]}></TextField>
                            </Grid>)}

                    </Grid>
                </Grid>
            </Container>


        </div >
    }
}


const mapStateToProps = state => {
    return {
        categories: state.categories.sort((a, b) => a.name.localeCompare(b.name)),
        // office: state.office,
        departements: state.departements.sort((a, b) => a.id - b.id),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchOffice: (id) => dispatch(doFetchOffice(id)),
        fetchDepartements: _ => dispatch(fetchDepartements()),
        fetchCategories: _ => dispatch(fetchCategories()),
        createOffice: (office) => dispatch(doCreateOffice(office)),
        updateOffice: (id, office) => dispatch(doUpdateOffice(id, office)),
        addErrorMessage: (err) => dispatch(addErrorMessage(err)),
        dismissErrorMessage: (id) => dispatch(dismissErrorMessage(id)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(withStyles(styles)(HomePage)));