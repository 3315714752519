import Api from '../Api';

export const REQUEST_DEPARTEMENTS = 'REQUEST_DEPARTEMENTS';
export const REQUEST_DEPARTEMENTS_SUCCESS = 'REQUEST_DEPARTEMENTS_SUCCESS';
export const REQUEST_DEPARTEMENTS_ERROR = 'REQUEST_DEPARTEMENTS_ERROR';

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const REQUEST_CATEGORIES_SUCCESS = 'REQUEST_CATEGORIES_SUCCESS';
export const REQUEST_CATEGORIES_ERROR = 'REQUEST_CATEGORIES_ERROR';

export const REQUEST_OFFICES = 'REQUEST_OFFICES';
export const REQUEST_OFFICES_SUCCESS = 'REQUEST_OFFICES_SUCCESS';
export const REQUEST_OFFICES_ERROR = 'REQUEST_OFFICES_ERROR';

export const DELETE_OFFICE = 'DELETE_OFFICE';
export const DELETE_OFFICE_SUCCESS = 'DELETE_OFFICE_SUCCESS';
export const DELETE_OFFICE_ERROR = 'DELETE_OFFICE_ERROR';

export const FETCH_OFFICE = 'FETCH_OFFICE';
export const FETCH_OFFICE_SUCCESS = 'FETCH_OFFICE_SUCCESS';
export const FETCH_OFFICE_ERROR = 'FETCH_OFFICE_ERROR';

export const CREATE_OFFICE = 'CREATE_OFFICE';
export const CREATE_OFFICE_SUCCESS = 'CREATE_OFFICE_SUCCESS';
export const CREATE_OFFICE_ERROR = 'CREATE_OFFICE_ERROR';

export const UPDATE_OFFICE = 'UPDATE_OFFICE';
export const UPDATE_OFFICE_SUCCESS = 'UPDATE_OFFICE_SUCCESS';
export const UPDATE_OFFICE_ERROR = 'UPDATE_OFFICE_ERROR';

export const SET_SEARCH_TERMS = 'SET_SEARCH_TERMS';

export const DISMISS_ERROR_MESSAGE = 'DISMISS_ERROR_MESSAGE';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';

const requestDepartements = () => {
    return {
        type: REQUEST_DEPARTEMENTS,
    }
}

const requestDepartementsSuccess = (departements) => {
    return {
        type: REQUEST_DEPARTEMENTS_SUCCESS,
        departements
    }
}

const requestDepartementsError = (error) => {
    return {
        type: REQUEST_DEPARTEMENTS_ERROR,
        error
    }
}

const requestCategories = () => {
    return {
        type: REQUEST_CATEGORIES,
    }
}

const requestCategoriesSuccess = (categories) => {
    return {
        type: REQUEST_CATEGORIES_SUCCESS,
        categories
    }
}

const requestCategoriesError = (error) => {
    return {
        type: REQUEST_CATEGORIES_ERROR,
        error
    }
}

const requestOffices = () => {
    return {
        type: REQUEST_OFFICES,
    }
}

const requestOfficesSuccess = (offices) => {
    return {
        type: REQUEST_OFFICES_SUCCESS,
        offices
    }
}

const requestOfficesError = (error) => {
    return {
        type: REQUEST_OFFICES_ERROR,
        error
    }
}

const deleteOffice = (officeId) => {
    return {
        type: DELETE_OFFICE,
        officeId
    }
}

const deleteOfficeSuccess = (id) => {
    return {
        type: DELETE_OFFICE_SUCCESS,
        id
    }
}

const deleteOfficeError = (error) => {
    return {
        type: DELETE_OFFICE_ERROR,
        error
    }
}

const fetchOffice = (officeId) => {
    return {
        type: FETCH_OFFICE,
        officeId
    }
}

const fetchOfficeSuccess = (res) => {
    return {
        type: FETCH_OFFICE_SUCCESS,
        office: res,
    }
}

const fetchOfficeError = (error) => {
    return {
        type: FETCH_OFFICE_ERROR,
        error
    }
}

const createOffice = (office) => {
    return {
        type: CREATE_OFFICE,
        office
    }
}

const createOfficeSuccess = (res) => {
    return {
        type: CREATE_OFFICE_SUCCESS,
        office: res,
    }
}

const createOfficeError = (error) => {
    return {
        type: CREATE_OFFICE_ERROR,
        error
    }
}
const updateOffice = (id, office) => {
    return {
        type: UPDATE_OFFICE,
        id,
        office
    }
}

const updateOfficeSuccess = (res) => {
    return {
        type: UPDATE_OFFICE_SUCCESS,
        office: res,
    }
}

const updateOfficeError = (error) => {
    return {
        type: UPDATE_OFFICE_ERROR,
        error
    }
}

export const addErrorMessage = (error) => {
    return {
        type: ADD_ERROR_MESSAGE,
        error
    }
}

export const fetchDepartements = _ => (dispatch, getState) =>
    !getState().fetchingDepartements && getState().departements.length === 0
        ? dispatch(requestDepartements()) && Api.departements.list()
            .then(res => dispatch(requestDepartementsSuccess(res)))
            .catch(error => {
                dispatch(addErrorMessage({ id: "SERVER_UNREACHABLE", message: "Serveur injoignable", type: "error" }))
                return dispatch(requestDepartementsError(error));
            })
        : dispatch(requestDepartements()) && new Promise((resolve, reject) => resolve(dispatch(requestDepartementsSuccess(getState().departements))));

export const fetchOffices = _ => (dispatch, getState) =>
    !getState().fetchingOffices && !getState().officesFetched
        ? dispatch(requestOffices()) && Api.offices.list()
            .then(res => dispatch(requestOfficesSuccess(res)))
            .catch(error => {
                dispatch(addErrorMessage({ id: "SERVER_UNREACHABLE", message: "Serveur injoignable", type: "error" }))
                return dispatch(requestOfficesError(error));
            })
        : dispatch(requestOffices()) && new Promise((resolve, reject) => resolve(dispatch(requestOfficesSuccess(getState().offices))));

export const fetchCategories = _ => (dispatch, getState) =>
    !getState().fetchingCategories && getState().categories.length === 0
        ? dispatch(requestCategories()) && Api.categories.list()
            .then(res => dispatch(requestCategoriesSuccess(res)))
            .catch(error => {
                dispatch(addErrorMessage({ id: "SERVER_UNREACHABLE", message: "Serveur injoignable", type: "error" }))
                return dispatch(requestCategoriesError(error));
            })
        : dispatch(requestCategories()) && new Promise((resolve, reject) => resolve(dispatch(requestCategoriesSuccess(getState().categories))));

export const doDeleteOffice = officeId => dispatch =>
    dispatch(deleteOffice(officeId)) && Api.offices.delete(officeId)
        .then(res => {
            dispatch(addErrorMessage({ id: "DELETE_DONE", message: "L'établissement a été supprimé", type: "info" }));
            return dispatch(deleteOfficeSuccess(officeId));
        })
        .catch(error => {
            dispatch(addErrorMessage({ id: "DELETE_ERROR", message: "Impossible de supprimer l'établissement: " + error, type: "error" }))
            return dispatch(deleteOfficeError(error));
        })


export const doCreateOffice = office => dispatch =>
    dispatch(createOffice(office)) && Api.offices.create(office)
        .then(res => {
          //  dispatch(addErrorMessage({ id: "CREATION_DONE", message: <p>a</p>, type: "info" })); 
            return dispatch(createOfficeSuccess(res));
        })
        .catch(error => {
            dispatch(addErrorMessage({ id: "CREATION_ERROR", message: "Impossible de créer l'établissement: " + error, type: "error" }))
            return dispatch(createOfficeError(error));
        })

export const doUpdateOffice = (id, office) => dispatch =>
    dispatch(updateOffice(id, office)) && Api.offices.update(id, office)
        .then(res => {
         //   dispatch(addErrorMessage({ id: "UPDATE_DONE", message: "L'établissement a été mis à jour", type: "info" }));
            return dispatch(updateOfficeSuccess(res));
        })
        .catch(error => {
            dispatch(addErrorMessage({ id: "UPDATE_ERROR", message: "Impossible de mettre à jour l'établissement: " + error, type: "error" }))
            return dispatch(updateOfficeError(error));
        })

export const doFetchOffice = officeId => dispatch =>
    dispatch(fetchOffice(officeId)) && Api.offices.get(officeId)
        .then(res => dispatch(fetchOfficeSuccess(res)))
        .catch(error => {
            dispatch(addErrorMessage({ id: "OFFICE_FETCH_ERROR", message: "Impossible de charger l'établissement: " + error, type: "error" }))
            return dispatch(fetchOfficeError(error))
        })


export const setSearchTerms = searchTerms => dispatch => {
    return dispatch({
        type: SET_SEARCH_TERMS,
        searchTerms
    })
}

export const dismissErrorMessage = id => dispatch => {
    return dispatch({
        type: DISMISS_ERROR_MESSAGE,
        id
    })
}