import React, { Component } from 'react';
import { Grid, Button } from '@material-ui/core';
import { setSearchTerms, fetchCategories, fetchDepartements, fetchOffices, REQUEST_CATEGORIES_ERROR, REQUEST_DEPARTEMENTS_ERROR, REQUEST_OFFICES_ERROR } from '../redux/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import Auth0 from '../Auth0';
import Api from '../Api';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

const styles = {
    bar: {
        height: "90px",
        padding: "10px",
        background: "rgba(255,255,255,0.90)",
        boxShadow: "0 0 10px 0 rgba(0,0,0,0.10)"
    },
    logo: {
        margin: "10px 0 0 30px"
    },
    searchPaper: {
        
    },
    input: {
        
    },
    addButton: {
        
    }
};

class TopBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            searchQuery: ""
        }
    }


    render() {
        const { title, classes, onSearch } = this.props;

        return <div className={classes.bar}>
            <Grid container style={{ position: "relative", top: "5px" }} direction="row" alignItems="center" spacing={4} >
                <Grid item xs={4}>
                    {title}
                </Grid>
                <Grid item xs={5} style={{ textAlign: "center" }}>
                    {onSearch && <Paper className={classes.searchPaper}>
                        <InputBase
                            className={classes.input}
                            placeholder="Rechercher"
                            inputProps={{ 'aria-label': 'Rechercher' }}
                            onChange={(a) => onSearch(a.target.value)}
                        />
                    </Paper>}
                </Grid>
                <Grid item xs={3}>
                    <Button onClick={() => this.props.history.push('/edit')} className={classes.addButton}>+ Ajouter un établissement</Button>
                    <IconButton onClick={() => Auth0.signOut()}>
                        <PowerSettingsNewIcon style={{ color: "black", fontSize: "30px" }} />
                    </IconButton>
                </Grid>

            </Grid>
        </div>
    }
}


const mapStateToProps = state => {
    return {

    }
}

const mapDispatchToProps = dispatch => {
    return {

    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(withStyles(styles)(TopBar)));