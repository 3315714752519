import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { Grid, Table, TableBody, Container, TableCell, TableHead, MenuItem, Button, Link, Menu, Fab, TableRow, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, InputBase } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import { MoreVert, KeyboardArrowDown, KeyboardArrowUp, Delete, Edit, PowerSettingsNew } from '@material-ui/icons/';
import { setSearchTerms, fetchCategories, fetchDepartements, fetchOffices, doDeleteOffice, REQUEST_CATEGORIES_ERROR, REQUEST_DEPARTEMENTS_ERROR, REQUEST_OFFICES_ERROR } from '../redux/actions';
import Auth0 from '../Auth0';

const styles = {
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        minWidth: 650
    },
};

class HomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            sortBy: "name",
            order: 1,
            search: "",
            page: 0,
            offices: [],
            deleteConfirmDialog: {
                open: false,
                officeId: null,
                officeName: null,
            }
        };

        props.fetchCategories();
        props.fetchDepartements();
        props.fetchOffices();
    }

    sortBy = (column) => {
        if (this.state.sortBy == column) {
            this.setState({
                order: -this.state.order,
            })
        } else {
            this.setState({
                order: 1,
                sortBy: column,
            })
        }

    }

    openPage = (page) => {
        this.setState({
            page
        })
    }


    handleOpenDeleteConfirmDialog = (officeId, officeName) => {
        this.setState({
            deleteConfirmDialog: {
                open: true,
                officeId,
                officeName,
            }
        });
    };

    handleDeleteConfirmDialogYesClick = () => {
        this.props.deleteOffice(this.state.deleteConfirmDialog.officeId)
            .then(() => {
                this.setState({
                    deleteConfirmDialog: {
                        open: false,
                        officeId: null,
                        officeName: null,
                    }
                });
            });
    };

    handleDeleteConfirmDialogNoClick = () => {
        this.setState({
            deleteConfirmDialog: {
                open: false,
                officeId: null,
                officeName: null,
            }
        });
    };


    upDown = (state, category) => {
        const sortingUp = state.sortBy === category && state.order < 0;
        const sortingDown = state.sortBy === category && state.order > 0;
        return <div style={{ width: "20px", position: "relative", top: "6px", display: "inline-flex" }} onClick={() => this.sortBy(category)}>
            <IconButton style={{ padding: 0, width: "30px", height: "30px" }} onClick={() => this.sortBy(category)}>
                <KeyboardArrowDown style={{ position: "relative", left: "12px", top: "6px", color: sortingUp ? "#04CDD9" : "#CCC" }} />
                <KeyboardArrowUp style={{ position: "relative", left: "-12px", top: "-5px", color: sortingDown ? "#04CDD9" : "#CCC" }} />
            </IconButton>
        </div>
    }

    handleMenuOpen = event => {
        this.setState({
            menuAnchor: event.currentTarget
        });
    };

    handleMenuClose = () => {
        this.setState({
            menuAnchor: null
        });
    };

    render() {
        const sortedOffices = this.props.offices
            .sort((a, b) => {
                let sortValue = 0;
                if (this.state.sortBy === "departement") {
                    sortValue = this.state.order * (a.departement && a.departement.id || "").localeCompare(b.departement && b.departement.id || "");
                } else {
                    sortValue = this.state.order * (a[this.state.sortBy] || "").localeCompare(b[this.state.sortBy] || "");
                }
                if (sortValue === 0) {
                    sortValue = a._id.localeCompare(b._id);
                }
                return sortValue;
            })
            .filter(office =>
                !this.state.search || !office
                || (office.name && office.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                || (office.city && office.city.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                || (office.departement && office.departement.name && office.departement.name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                || (office.category && office.category.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                || (office.address1 && office.address1.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
                || (office.address2 && office.address2.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1)
            );

        const page = sortedOffices.slice(20 * this.state.page, 20 * (this.state.page + 1));

        const { classes } = this.props;

        return <div style={{ backgroundColor: "#FAFAFF" }}>
            <Container maxWidth={false} width={1} style={{ padding: "10px 0 10px 0", height: "100px" }} className="topBar">
                <Grid container style={{ position: "relative", top: "5px" }} direction="row" alignItems="center" spacing={4} >
                    <Grid item xs={3}>
                        <img style={{ marginLeft: "30px" }} src="/oxance-logo-annuaire.svg" />
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "center" }}>
                        <Paper className="searchPaper">
                            <InputBase
                                className="searchInput"
                                placeholder="Rechercher"
                                inputProps={{ 'aria-label': 'Rechercher' }}
                                onChange={(a) => this.setState({ search: a.target.value, page: 0 })}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={5} style={{ textAlign: "right" }}>
                        <Button onClick={() => this.props.history.push('/edit')} className={"addButton"}>+ Ajouter un établissement</Button>
                        <IconButton onClick={() => Auth0.signOut()}>
                            <PowerSettingsNew style={{ color: "black", fontSize: "30px" }} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
            {this.props.officesFetched && <Container maxWidth={false} width={1} style={{ padding: "30px" }}>
                <Grid  container style={{ position: "relative", top: "5px" }} direction="row" alignItems="center" spacing={5} >
                    <Grid item xs={12} style={{ paddingBottom: 0 }}>
                        <p style={{ marginBottom: 0, paddingBottom: "20px", borderBottom: "1px solid #EBEDF8" }}>{this.props.offices.length} établissements{sortedOffices.length !== this.props.offices.length && <span> ({sortedOffices.length} affichés)</span>}</p>
                    </Grid>
                    <Grid item xs={12}>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{textAlign: "center"}}>Établissement {this.upDown(this.state, 'name')}</TableCell>
                                    <TableCell style={{textAlign: "center"}}><div style={{ marginTop: "10px" }}>Adresse</div></TableCell>
                                    <TableCell style={{textAlign: "center", minWidth: "50px"}}>CP {this.upDown(this.state, 'postalcode')}</TableCell>
                                    <TableCell style={{textAlign: "center", minWidth: "70px"}}>Ville {this.upDown(this.state, 'city')}</TableCell>
                                    <TableCell style={{textAlign: "center", minWidth: "110px"}}>Département {this.upDown(this.state, 'departement')}</TableCell>
                                    <TableCell style={{textAlign: "center", minWidth: "90px"}}>Catégorie {this.upDown(this.state, 'category')}</TableCell>
                                    <TableCell style={{textAlign: "center"}}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {page.map((office, index) =>
                                    <TableRow key={index} style={{ backgroundColor: "#FFFFFF" }}>
                                        <TableCell><Link style={{ color: "#FF1C57", fontWeight: "bold", cursor: "pointer" }} onClick={() => this.props.history.push('/edit?id=' + office._id)}>{office.name}</Link></TableCell>
                                        <TableCell>{office.address1}</TableCell>
                                        <TableCell>{office.postalcode}</TableCell>
                                        <TableCell>{office.city}</TableCell>
                                        <TableCell>{office.departement && office.departement.id + " - " + office.departement.name}</TableCell>
                                        <TableCell>{office.category}</TableCell>
                                        <TableCell>
                                            <PopupState variant="popover" popupId="demo-popup-menu">
                                                {popupState => (
                                                    <React.Fragment>
                                                        <Button  {...bindTrigger(popupState)}>
                                                            <MoreVert style={{ color: "#04CDD9" }} />
                                                        </Button>
                                                        <Menu {...bindMenu(popupState)}>
                                                            <MenuItem onClick={() => this.props.history.push('/edit?id=' + office._id)}><Edit style={{ marginRight: "8px", color: "#04CDD9" }} />Modifier</MenuItem>
                                                            <MenuItem onClick={() => { this.handleOpenDeleteConfirmDialog(office._id, office.name); this.handleMenuClose(); popupState.close(); }}><Delete style={{ marginRight: "8px", color: "#04CDD9" }} />Supprimer</MenuItem>
                                                        </Menu>
                                                    </React.Fragment>
                                                )}
                                            </PopupState>

                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>

                    </Grid>
                    <Grid item xs={6} style={{ paddingBottom: "30px" }}>
                        {sortedOffices.length > 0
                            ? <span style={{ color: "#AAB2C0" }}>Afficher <span style={{ color: "#001F00" }}>{this.state.page * 20 + 1}</span> à <span style={{ color: "#001F00" }}>{Math.min((this.state.page + 1) * 20, sortedOffices.length)}</span> sur {sortedOffices.length} établissements</span>
                            : <span style={{ color: "#AAB2C0" }}>Aucun résultat</span>
                        }
                    </Grid>
                    <Grid item xs={6} style={{ paddingBottom: "30px", textAlign: "right" }}>
                        {[...Array(Math.floor(sortedOffices.length / 20) + 1).keys()].map(page =>
                            <Fab style={{ boxShadow: "none", margin: "4px", width: "41px", height: "41px", border: page === this.state.page ? "none" : "1px solid #C7CDD9", color: page === this.state.page ? "white" : "#C7CDD9", backgroundColor: page === this.state.page ? "#FF1C57" : "white" }} onClick={() => this.openPage(page)}>{page + 1}</Fab>)
                        }

                    </Grid>
                </Grid>
            </Container>}

            <Dialog open={this.state.deleteConfirmDialog.open} onClose={() => this.handleCloseDeleteConfirmDialog()} aria-labelledby="draggable-dialog-title" >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Êtes-vous sûr de vouloir supprimer l'établissement "{this.state.deleteConfirmDialog.officeName}" ?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.handleDeleteConfirmDialogYesClick()} color="primary">Oui</Button>
                    <Button onClick={() => this.handleDeleteConfirmDialogNoClick()} color="primary">Non</Button>
                </DialogActions>
            </Dialog>
        </div >
    }
}


const mapStateToProps = state => {
    return {
        categories: state.categories,
        offices: state.offices,
        officesFetched: state.officesFetched,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCategories: () => dispatch(fetchCategories()),
        fetchDepartements: () => dispatch(fetchDepartements()),
        fetchOffices: () => dispatch(fetchOffices()),
        deleteOffice: (officeId) => dispatch(doDeleteOffice(officeId)),
        setSearchTerms: (searchTerms) => dispatch(setSearchTerms(searchTerms)),
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withRouter(withStyles(styles)(HomePage)));