import {
    REQUEST_DEPARTEMENTS,
    REQUEST_DEPARTEMENTS_ERROR,
    REQUEST_DEPARTEMENTS_SUCCESS,

    REQUEST_CATEGORIES,
    REQUEST_CATEGORIES_ERROR,
    REQUEST_CATEGORIES_SUCCESS,

    REQUEST_OFFICES,
    REQUEST_OFFICES_ERROR,
    REQUEST_OFFICES_SUCCESS,

    FETCH_OFFICE,
    FETCH_OFFICE_ERROR,
    FETCH_OFFICE_SUCCESS,

    DELETE_OFFICE_SUCCESS,

    DISMISS_ERROR_MESSAGE,
    ADD_ERROR_MESSAGE,

    SET_SEARCH_TERMS,
    CREATE_OFFICE_SUCCESS,
    UPDATE_OFFICE_SUCCESS,
} from './actions';

const INITIAL_STATE = {
    categories: [],
    departements: [],
    offices: [],
    officesFetched: false,
    fetchingDepartements: false,
    fetchingCategories: false,
    searchTerms: [],
    errorMessages: []
}

const appReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REQUEST_DEPARTEMENTS:
            return {
                ...state,
                fetchingDepartements: true,
            }
        case REQUEST_DEPARTEMENTS_SUCCESS:
            return {
                ...state,
                departements: action.departements,
                fetchingDepartements: false,
            }
        case REQUEST_DEPARTEMENTS_ERROR: {
            return {
                ...state,
                fetchingDepartements: false,
            }
        }
        case REQUEST_CATEGORIES:
            return {
                ...state,
                fetchingCategories: true,
            }
        case REQUEST_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.categories,
                fetchingCategories: false,
            }
        case REQUEST_CATEGORIES_ERROR: {
            return {
                ...state,
                fetchingCategories: false,
            }
        }
        case REQUEST_OFFICES:
            return {
                ...state,
                fetchingOffices: true,
            }
        case REQUEST_OFFICES_SUCCESS:
            return {
                ...state,
                offices: action.offices,
                fetchingOffices: false,
                officesFetched: true,
            }
        case REQUEST_OFFICES_ERROR: {
            return {
                ...state,
                fetchingOffices: false,
            }
        }
        case FETCH_OFFICE:
            return {
                ...state,
                fetchingOffice: true,
            }
        case FETCH_OFFICE_SUCCESS:
            return {
                ...state,
                office: action.office,
                fetchingOffice: false,
            }
        case FETCH_OFFICE_ERROR: {
            return {
                ...state,
                fetchingOffice: false,
            }
        }
        case SET_SEARCH_TERMS: {
            return {
                ...state,
                searchTerms: action.searchTerms
            }
        }
        case FETCH_OFFICE_SUCCESS:
            return {
                ...state,
                office: action.office,
                fetchingOffice: false,
            }
        case ADD_ERROR_MESSAGE: {
            return {
                ...state,
                errorMessages: [...state.errorMessages.filter(errorMessage => errorMessage.id !== action.error.id), action.error]
            }
        }
        case DISMISS_ERROR_MESSAGE: {
            return {
                ...state,
                errorMessages: state.errorMessages.filter(errorMessage => errorMessage.id !== action.id)
            }
        }
        case DELETE_OFFICE_SUCCESS: {
            console.log(action.id, [...state.offices.filter(o => o._id !== action.id)]);
            return {
                ...state,
                offices: [...state.offices.filter(o => o._id !== action.id)]
            }
        }
        case UPDATE_OFFICE_SUCCESS: {
            return {
                ...state,
                offices: [
                    ...state.offices.filter(o => o._id !== action.office._id),
                    action.office
                ]
            }
        }
        case CREATE_OFFICE_SUCCESS: {
            return {
                ...state,
                offices: [
                    ...state.offices,
                    action.office
                ]
            }
        }
        default:
            return state
    }
}

export default appReducer;